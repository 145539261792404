export const styles = {
    editorStyle: { backgroundColor: 'inherit', borderRadius: 15, padding: 5 },
    wrapperStyle: { marginBottom: 10, borderWidth: 0.5 }
}

export const pickerColors = [
    'inherit',
    "rgb(29, 52, 63)",
    "rgb(239, 78, 88)",
    "rgb(154, 149, 200)",
    "rgb(97, 194, 164)",
    "rgb(255, 209, 78)",
    "rgb(221, 227, 225)",
    "rgb(235, 241, 239)",
    "rgb(118, 118, 118)",
    "rgb(247, 148, 58)",
    "rgb(119, 193, 95)",
]
