import { DeleteIcon } from '@chakra-ui/icons';
import { Spinner } from '@chakra-ui/react';
import { useState } from 'react';
import FileService from '../../services/FileService';
import Dropzone from '../Dropzone/Dropzone';
import { Preview } from "./Components/Preview";
import { acceptedFilesMap } from './utils';

/**
 * Componente FileUploader que permite a los usuarios cargar archivos.
 *
 * @component
 * @param {Object} props Propiedades del componente.
 * @param {'image'|'chart'|'video'|'csv'} [props.type='image'] - Tipo de archivo que se permite cargar. Por defecto es 'image'.
 * @param {(file:File)=>any} [props.onUpload] - Función que se invoca cuando un archivo se ha cargado correctamente.
 * @param {()=>any} [props.onDelete] - Función que se invoca cuando un archivo se ha eliminado.
 * @param {(error, source:'upload'|'loadPreview') =>any} [props.onError] - Función que se invoca cuando hay un error al cargar o eliminar un archivo.
 * @param {(file) => File } [props.beforeUpload=(file) => { return file }] - Función que se invoca antes de cargar el archivo, permite modificar o validar el archivo antes de la carga.
 * @param {string} [props.previewSrc] - Ruta o URL del archivo para mostrar una vista previa.
 * @param {boolean} [props.hasError=false] - Indica si el componente tiene error. Por defecto es 'false'.
 * @param {'public'|'private'} [props.storageType='public'] - Tipo de almacenamiento
 * @returns {JSX.Element} Renderiza el componente FileUploader.
 */
export function FileUploader({ storageType = 'public', style = {}, type = 'image', onUpload = (file) => { }, onDelete = () => { }, onError, beforeUpload = (file) => { return file }, previewSrc, hasError = false }) {
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)

    async function onFileRead({ file }) {
        setLoading(true)
        try {
            file = beforeUpload(file)
            const resp = await FileService.uploadFile(file, storageType)
            const serverFile = resp.data
            setFile(serverFile)
            onUpload(serverFile)
        } catch (error) {
            console.error(error)
            if (onError) return onError(error, 'upload')
            throw error
        }
        finally {
            setLoading(false)
        }
    }

    async function _onDelete() {
        if (file?.filename) await FileService.deleteFile(file.filename, storageType)
        onDelete(file)
        setFile(null)
    }

    return (
        <>
            <div style={{ ...styles.container, ...style }}>
                {
                    loading ? <Spinner /> :
                        previewSrc ?
                            <>
                                <Preview
                                    type={type} src={previewSrc}
                                    onError={(error) => onError(error, 'loadPreview')}
                                    componentStyles={{ ...styles.preview, }}
                                    containerStyles={{ ...styles.previewContainer, ...style }}
                                // name={file.originalname}
                                />

                                <DeleteIcon boxSize={5} ml={10} cursor={'pointer'} onClick={_onDelete} />
                            </>
                            :
                            <Dropzone onFileRead={onFileRead} accept={acceptedFilesMap[type]} error={hasError} />
                }
            </div>
        </>
    )
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 200
    },
    previewContainer: {
        width: 300
    },
    preview: {
        maxHeight: 180,
        maxWidth: 280
    },
}
